<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="45%">
    <el-form ref="form" :model="formAdd" :rules="ruleContact">
      <el-form-item label="客户姓名" :label-width="formLabelWidth" prop="customerName">
        <el-input v-model="formAdd.customerName" autocomplete="off" placeholder="请输入用户昵称" ></el-input>
      </el-form-item>
      <el-form-item label="联系方式" :label-width="formLabelWidth" prop="customerPhone">
        <el-input v-model="formAdd.customerPhone" autocomplete="off" placeholder="请输入电话" ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: "EditCustomer",
  data(){
    var checkName = (rule, value, callback) => {
      let reg = /^[a-zA-Z\u4e00-\u9fa5]+$/g;
      if (value.length > 20) {
        callback(new Error("输入的姓名过长"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入中文或英文"));
      } else {
        callback();
      }
    };
    var checkPhone = (rule, value, callback) => {
      let reg_mobile = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
      let reg_phone = /^(0d{2,3})-?(d{7,8})$/;
      if (!reg_mobile.test(value)) {
        callback(new Error("请输入正确的手机或座机号码"));
      } else {
        callback();
      }
      if (!reg_phone.test(value)) {
        callback(new Error("请输入正确的手机或座机号码"));
      } else {
        callback();
      }
    };
    return{
      edit:0,//0 新增 ，1编辑
      dialogFormVisible:false,
      dialogFormVisible2:false,
      title:'新增',
      formLabelWidth:"100px",
      labelPosition:"right",
      formAdd:{
        customerName:'',//名称
        customerPhone:"",//联系方式
      },
      ruleContact:{
        customerName: [
          { required: true, message: "请输入默认联系人姓名", trigger: "blur" },
          { validator: checkName, trigger: "blur" }
        ],
        customerPhone:[
          { required: true, message: "请输入客户电话", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" }
        ]
      }
    }
  },
  methods:{
    add(row) {
      this.title = '添加客户'
      this.dialogFormVisible = true
    },
    close(){
      this.$refs["form"].resetFields();
      this.formAdd = this.$options.data().formAdd
      this.dialogFormVisible = false
      this.$emit('close')
    },
    save(){
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          // const { msg } = await doEdit(this.form)
          // this.$baseMessage(msg, 'success')

          this.dialogFormVisible = false
          this.$emit('save',this.formAdd)
          this.$refs['form'].resetFields()
          this.form = this.$options.data().formAdd
        } else {
          return false
        }
      })
    },
  }
}
</script>

<style  lang="less">

</style>
