<template>
  <div class="handle-container">
    <div class="bread">
      <span @click="home()" class="gray">操作台 / </span>
      <span>客户库</span></div>
    <el-row class="table-body">
      <div class="line clearfix">
        <h5 class="fl title">客户库</h5>
        <div class="fr search" >
          <el-input  type="text" placeholder="请输入客户联系方式" v-model="customerPhone"></el-input>
          <el-select v-model="leaseStatus" placeholder="请选择租车状态">
            <el-option
                v-for="item in pList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-button type="primary" size="small" @click="toSearch">查询</el-button>
          <el-button type="primary" size="small" plain @click="addNew" v-if="showRouter('admin')">新增</el-button>
        </div>
      </div>
      <div class="list">
        <el-table
              :data="tableData"
              style="width: 100%">
          <el-table-column
              fixed
              type="index"
              label="序号"
              width="80">
          </el-table-column>
          <el-table-column
              prop="customerName"
              label="客户名"
              min-width="200">
          </el-table-column>
          <el-table-column
              prop="customerPhone"
              label="客户电话"
              min-width="200">
          </el-table-column>
          <el-table-column
              prop="leaseStatus"
              label="租车状态"
              min-width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.saleOrRentType==null" class="red">未租/未购</span>
              <span v-if="scope.row.saleOrRentType==1" class="blue">已租</span>
              <span v-if="scope.row.saleOrRentType==2" class="blue">已购</span>
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="200">
            <template slot-scope="scope">
              <el-button
                  @click="deleteThis(scope.row)"
                  class="red"
                  type="text"
                  size="small">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>

      </div>
      <div class="pages">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :hide-on-single-page="showPage"
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="total">
        </el-pagination>
      </div>
    </el-row>
    <delete-confirm ref="delete" :content="delContent" @save="toDelete"></delete-confirm>
    <edit-customer ref="add" @save="saveCustomer"></edit-customer>
  </div>
</template>

<script>
import { getList,delOne,addOne,getListNew } from "@/utils/api/customer";
import DeleteConfirm from "@/components/DeleteConfirm";
import EditCustomer from "@/components/EditCustomer";
import {mapGetters} from "vuex";

export default {
  name: "customer",
  components:{
    DeleteConfirm,
    EditCustomer
  },

  data(){
    return{
      total:0, //总条数
      pageNo:1,
      pageSize:10,
      showPage:true,
      showOperate:false,
      operator:"",
      customerPhone:"",//客户电话
      leaseStatus:"",//租车状态
      delDialogVisible: false,
      delContent:"",
      deal:"",
      pList:[
        {
          value: '',
          label: '全部'
        },
        {
          value: '1',
          label: '已租'
        },
        {
          value: '0',
          label: '未租'
        },
      ],
      tableData: [ //人员管理
        {
          id:1,
          nickName: "昵称",//昵称
          roleName:"",//身份
          realName:"名字",//真实姓名
          userPhone:"11111111111"//手机号码
        },
      ],

    }
  },
  created() {
    this.getList()

  },
  mounted() {
    // this.refresh()
  },
  methods:{
    refresh(){
      this.$router.go(0)
    },
    home(){
      this.goHome()
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getList()
    },
    showRouter(permission){
      if (permission==='admin'){
        return true
      }else{
        let result = this.$store.state.permissions.findIndex(item => item=== permission)
        if (result!=-1){
          return true
        }else{
          return false
        }
      }
    },
    getList(){
      let form={
        page:this.pageNo,
        rows:this.pageSize,
        type:3
      }
      if (this.customerPhone!=""){
        form.customerPhone=this.customerPhone
      }
      if (this.leaseStatus!=""){
        form.leaseStatus=this.leaseStatus
      }
      getListNew(form).then(res=>{
        if(res.code==0){
          this.tableData=res.data.list
          this.total= Number(res.data.total)
        }else{
          this.$baseMessage(res.msg,'warning')
        }
      })
    },
    toSearch(){
      this.pageNo=1
      this.getList()
    },
    deleteThis(row){
      this.deal=row
      this.delContent="删除客户："+row.customerName
      this.delDialogVisible= true
      this.$refs['delete'].showEdit()
    },
    toDelete(){
      let form={
        id:this.deal.id
      }
      delOne(form).then(res=>{
        if(res.code ===0){
          this.getList()
        }else{

        }
      })
    },
    addNew(){
      this.$refs['add'].add()
    },
    saveCustomer(form){
      addOne(form).then(res=>{
        if (res.code==0){
          this.$baseMessage('客户添加成功','success')
          // setTimeout(()=>{
          //   this.getList()
          // }, 1000)
          this.getList()
        }else{
          this.$baseMessage(res.msg,'error')
        }
      })
    }
  }
}
</script>

<style lang="less">
.table-body{
  background-color: #FFFFFF;
  padding:30px ;
  padding-top: 0;
  .line{
    height: 90px;
    line-height: 90px;
    .title{
      font-size: 16px;
      font-weight: 800;
      color: #333333;
    }
    .search{
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: flex-end;
      .el-input{
        font-size: 12px;
        margin-right: 12px;
        width: inherit;
        padding-left: 10px;
        .el-input__inner{
          border: none;
          background-color: #FAFAFA;
        }
      }

    }
  }
}
.el-button--text{
  &:last-child{
    &:hover{
      color: red;
    }
  }
}
</style>
